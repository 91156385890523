import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"

import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime } from "../admin/Config/CommonFunction";
import { Helmet } from "react-helmet";

//const CaptionedSlider = withCaption(AwesomeSlider);

const CategoryScreen = () => {

    const navigate = useNavigate();
    const {allCategory}=UserProfile();

    useEffect(() => {
        window.scrollTo(0, 0)
    }
      , [allCategory]);
    
      const gotoMenu=async(data)=>{
        navigate('/menu',{ state: { cat_id: data.id } })
    }

    return(
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Affordable Cafe in kalighat| Categories | The Lighter Side Cafe</title>
            <meta name="description" content="Affordable cafe in Kalighat offering a cozy ambiance and delicious menu. Explore unique categories at The Lighter Side Cafe for a delightful experience!" />
            <link rel="canonical" href={"https://thelighterside.in/"} ></link>
            <meta property="og:title" content="Affordable Cafe in kalighat| Categories | The Lighter Side Cafe" />
            <meta property="og:description" content="Affordable cafe in Kalighat offering a cozy ambiance and delicious menu. Explore unique categories at The Lighter Side Cafe for a delightful experience!" />
            <meta property="og:image" content="https://thelighterside.in/uploads/banner_images/ca896c1a-8fff-4a90-8160-f69c8eba1a47_BANNER.jpg" />
            <meta property="og:url" content="https://thelighterside.in/" />
            <meta property="og:type" content="website" />
      </Helmet>
     <Header/>

  <div className="global"> 
    
 
<div className="container">
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading">Our Categories</h3>
            </div>
            <div className="row d-flex align-items-center" style={{flexWrap:'wrap'}}>
                {allCategory.map((data, index) => (
                    <div className="col-lg-4 mb-5" key={index} style={{cursor:'pointer'}} onClick={()=>gotoMenu(data)}>
                        <div className="thumbnail">
                            <figure style={{margin:'0 0 0 0'}}>
                                <img src={config.FILE_URL+data.imageName} alt="" style={{width:'100%'}}/>
                            </figure>
                            <div className="caption">
                                <p className="price">{data.name}</p>
                                {/* <div>
                                    <p className="description">Cum sociistoque pentibus et</p>
                                    <p className="description">Scetur rimulug maleada odior</p>
                                </div> */}
                                {/* <p className="price">$ 14.25</p> */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
    </div>

    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default CategoryScreen;