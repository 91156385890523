import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';


import config from "../Config/Config.json"
import event1 from '../img/2150951243.jpg'

import phone_gold from '../img/phone_gold.png'
import location_gold from '../img/location_gold.png'
import fb_gold from '../img/fb_gold.png'
import insta_gold from '../img/insta_gold.png'
import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime , convertTime} from "../admin/Config/CommonFunction";
import { Modal } from "react-bootstrap";
import Banner from "../common/Banner";
import Category from "../common/Category";
import Testimonial from "../common/Testimonial";
import { Helmet } from "react-helmet";

//const CaptionedSlider = withCaption(AwesomeSlider);

const HomeScreen = () => {

    const navigate = useNavigate();
    const {allCombo,allEvent,allGallery,customerQuery}=UserProfile();
    const [name,setName]=useState('')
    const [phone,setPhone]=useState('')
    const [email,setEmail]=useState('')
    const [desc,setDesc]=useState('')
    const [comboModal,setComboModal]=useState(false)
    const [comboName,setComboName]=useState('')
    const [comboDesc,setComboDesc]=useState('')

    

    const gotoEvents=()=>{
        navigate('/event')
    }
    const gotoDetails=(id)=>{
        navigate('/eventdetails',{ state: { event_id: id } })
      }
    const gotoGallery=()=>{
        navigate('/gallery')
    }
    const gotoCombo=()=>{
        navigate('/combo')
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }
      , [allCombo,allEvent,allGallery]);



    const sendEmail=async()=>{
    let data=
    {
        "name": name,
        "email": email,
        "contactNo": phone,
        "comment": desc
    }
    let resp= await customerQuery(data)
    setName('')
    setEmail('')
    setPhone('')
    setDesc('')
    alert(resp.data.message)

    }
    const viewCombo=(value)=>{
        setComboName(value.name)
        setComboDesc(value.description)
        setComboModal(true)
    }
    const closeComboModal=()=>{
        setComboModal(false)
    }
    const gotoInsta=()=>{
        window.open('https://www.instagram.com/thelighterside_cafe', '_blank')
    }
    const gotoFb=()=>{
        window.open('https://www.facebook.com/thelightersidecafe', '_blank')
    }
    return(
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Best Cafe in South Kolkata | The Lighter Side Cafe</title>
            <meta name="description" content="Discover the best cafe in South Kolkata – The Lighter Side Cafe. Enjoy delicious food, cozy ambiance, and great coffee. Perfect for hangouts & work!" />
            <link rel="canonical" href={"https://thelighterside.in/"} ></link>
            <meta property="og:title" content="Best Cafe in South Kolkata | The Lighter Side Cafe" />
            <meta property="og:description" content="Discover the best cafe in South Kolkata – The Lighter Side Cafe. Enjoy delicious food, cozy ambiance, and great coffee. Perfect for hangouts & work!" />
            <meta property="og:image" content="https://thelighterside.in/uploads/banner_images/ca896c1a-8fff-4a90-8160-f69c8eba1a47_BANNER.jpg" />
            <meta property="og:url" content="https://thelighterside.in/" />
            <meta property="og:type" content="website" />
      </Helmet>
     <Header/>

  <div className="global"> 
    
 <Banner/>
<div className="container">
    <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 welcome-box mb-5">
            {/* <h2 className="home_header">Welcome!</h2> */}
            <h3 className="menu_heading">Who We Are</h3>
            {/* <p className="title">Our restaurant is excellent in every way from the friendly service to the delicious food.</p> */}
            {/* <div className="d-flex mt-3 mb-4">
            {/* <div className="col-lg-12">
                <img src={about} alt="about" style={{width:'100%'}}/>
            </div>
            <div className="col-lg-10">
                <p style={{textAlign:'justify'}}>
                        The Lighter Side is a concept cafe that offers a blend of Healthy and World Cuisine while serving the best coffee and  beverages in town. The concept was born out of  accumulation of ideas by the owners through tasting of different cuisines across 3 continents.<br/>
                        We are foodies who like to cook, serve and enjoy both authentic and fusion food varieties. The Lighter Side offers some of our chosen favourites that are created with health, taste and quality in mind.<br/>
                        
                </p>
                <p style={{textDecoration:'underline',fontSize:'1.5rem'}}>Owners Profile</p>
                    <p style={{textAlign:'justify'}}></p>
                <button className="btn-default btn1">Read More</button>
            </div>
            
            </div> */}

            <div className="row d-flex align-items-center" style={{flexWrap:'wrap'}}>
                <div className="col-lg-4">
                <div className="caption" style={{minHeight:'24rem',backgroundColor:'#f6e4c2'}}>
                            
                    <div className="event_block px-3 py-2">
                        <p className="title" style={{textAlign:'center',fontSize:'1.5rem',textDecoration:'underline'}}>About Us</p>
                        <p className="description mb-2" style={{color: '#020202'}}>
                            The Lighter Side is a concept cafe that offers a blend of Healthy and World Cuisine while serving the best coffee and  beverages in town. The concept was born out of  accumulation of ideas by the owners through tasting of different cuisines across 3 continents.<br/>
                            We are foodies who like to cook, serve and enjoy both authentic and fusion food varieties. The Lighter Side offers some of our chosen favourites that are created with health, taste and quality in mind.<br/>
                        </p>
                                
                    </div>

                            
                            
                        </div>
                </div>
                <div className="col-lg-4">
                <div className="caption" style={{minHeight:'24rem',backgroundColor:'#f6e4c2'}}>
                            
                    <div className="event_block px-3 py-2">
                        <p className="title" 
                        style={{textAlign:'center',fontSize:'1.5rem',textDecoration:'underline'}}>Mission & Vision</p>
                        <p className="description mb-2" style={{color: '#020202'}}>
                            Our Mission is to set up a Brand that will be synonymous with Quality and Service.<br/>
                            Our Vision is to provide Food and Drink for the Soul with a homegrown twist.<br/>
                            The owners invite you to be a part of this cullinary journey.
                        </p>
                                
                    </div>

                            
                            
                        </div>
                </div>
                <div className="col-lg-4">
                <div className="caption" style={{minHeight:'24rem',backgroundColor:'#f6e4c2'}}>
                            
                    <div className="event_block px-3 py-2">
                        <p className="title" 
                        style={{textAlign:'center',fontSize:'1.5rem',textDecoration:'underline'}}>Owner's Profile</p>
                        <p className="description mb-2" style={{color: '#020202'}}>
                        Hema Saha is a homemaker and foodie with a passion to create different food varieties from across the globe with a flavour of her own.<br/>
                        Abhijit Saha is a Career Banker who is a foodie himself with a determination to create a Food Chain Brand that will stand the test of time.
                        </p>
                                
                    </div>

                            
                            
                        </div>
                </div>
            </div>
            
        </div>
        
        
        
        <Category/>
    </div>
    </div>
{/* <div className="forcombo py-2 px-3">
    <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading_combo">Our Exclusive Combo</h3>
                <button className="btn-default btn2" onClick={gotoCombo}>View All</button>
            </div>
            <div className="row d-flex align-items-center justify-content-center" style={{flexWrap:'wrap'}}>
            {allCombo.map((data, index) => (
                <div className="col-lg-3 mb-5" key={index}>
                    <div className="thumbnail">
                        <figure style={{margin:'0 0 0 0'}}>
                            <img src={config.FILE_URL+data.imageName+'?d='+new Date()} alt="" style={{width:'100%',height:'342px'}}/>
                        </figure>
                        <div className="caption">
                            <div className="event_block">
                                    <p className="title">{data.name}</p>
                                    <p className="description mb-2">
                                        {data.description.substring(0, 120)}...
                                    </p>
                                    
                                    
                                </div>
                                <button className="btn-default btn2" onClick={()=>viewCombo(data)}>View Offer</button>
                            
                        </div>
                    </div>
                </div>
            ))}
           
            </div>
    </div>
</div> */}
    {/* <div className="container">
        
        <div className="col-lg-12 events mb-5">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading">Our Events</h3>
                <button className="btn-default btn1" onClick={gotoEvents}>View All</button>
            </div>
            <div className="row d-flex align-items-center" style={{flexWrap:'wrap'}}>
            {allEvent.map((data, index) => (
                <div className="col-lg-4" key={index}>
                    <div className="thumbnail">
                        <figure style={{margin:'0 0 0 0'}}>
                            
                            <img src={data.defaultImage===null?event1:config.FILE_URL+data.defaultImage} alt="" style={{width:'100%',height:'274px'}}/>
                        </figure>
                        <div className="caption">
                            
                            <div className="event_block">
                                <p className="title">{data.name}</p>
                                <p className="description mb-2">
                                    {data.description.substring(0, 120)}...
                                </p>
                                <p className="title">{convertDateTime(data.eventDate)} {convertTime(data.eventDate)}</p>
                            </div>

                            <button className="btn-default btn1" onClick={()=>gotoDetails(data.id)}>Read More</button>
                            
                        </div>
                        
                    </div>
                </div>
            ))}
            </div>
        </div>
    </div> */}

    <Testimonial/>

    <div className="forcombo gallery_back py-2 px-3 mb-5">
    <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading_combo">Our Gallery</h3>
                <button className="btn-default btn2" onClick={()=>gotoGallery()}>View Gallery</button>
            </div>
            <div className="row d-flex mb-3 px-3" style={{flexWrap:'wrap',flexDirection:'row'}}>
            {allGallery.slice(0, 10).map((data, index) => (
                    <div style={{width:'20%',padding:0}} key={index}>
                        <img src={config.FILE_URL+data.imageName} alt="" style={{width:'100%',height:'150px'}}/>
                    </div>
            ))}
            </div>
    </div>
</div>
    <div className="container">
        <div className="col-lg-12 form-box">
            <h3 className="menu_heading">Contact Us</h3>
            

                <div className="row d-flex" style={{flexWrap:'wrap'}}>
                        <div className="col-md-6">

                        <div className="fl1 labelName">
                            <div className="tmInput">
                                <input 
                                    className="from-control input_style" 
                                    name="Name" 
                                    placeholder="Your name" 
                                    type="text"
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                />
                                <input 
                                    name="Phone" 
                                    className="from-control input_style" 
                                    placeholder="Phone number" 
                                    type="text"
                                    value={phone}
                                    onChange={(e)=>setPhone(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="fl1 labelPhone mb-4">
                            <div className="tmInput">
                                <input 
                                    className="from-control input_style" 
                                    name="Name" 
                                    placeholder="Email" 
                                    type="text"
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    />
                                <input 
                                name="text" 
                                className="from-control input_style" 
                                placeholder="Comments" 
                                type="text"
                                value={desc}
                                onChange={(e)=>setDesc(e.target.value)}
                                />
                            </div>     
                        </div>

                        <button className="btn-default btn1" onClick={()=>sendEmail()}>Send</button>

                        </div>
                        <div className="col-md-6">
                            <p className="title d-flex align-items-center mx-2 my-2">
                                <img src={phone_gold}/>&nbsp; +91 9163262716
                            </p>
                            <p className="title d-flex align-items-center mx-2">
                                <img src={location_gold}/> 
                                &nbsp; 35A, Raja Basanta Roy Road,<br/>
                                &nbsp; Police Station Tollygunge,<br/>
                                &nbsp; Kolkata – 700029
                            </p>
                            <p className="title d-flex align-items-center mx-2">
                                <img src={fb_gold} onClick={gotoFb} style={{cursor:'pointer'}}/> 
                                <img src={insta_gold} onClick={gotoInsta} style={{cursor:'pointer'}}/> 
                            </p>
                        </div>
                </div>
            
                
            
        </div>
    </div>
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    

    <Modal
          show={comboModal}
          onHide={closeComboModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
                <Modal.Title>{comboName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                
                
                
                <p>{comboDesc}</p>
                

              </div>
            </div>
          </Modal.Body>
          </Modal>
</div>
  </div>
    )
}

export default HomeScreen;