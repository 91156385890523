import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"

import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime } from "../admin/Config/CommonFunction";


//const CaptionedSlider = withCaption(AwesomeSlider);

const MenuScreen = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {allCategory,getAllMenuById}=UserProfile();
    const [categoryDetails,setCategoryDetails]=useState('')
    const [allMenu,setAllMenu]=useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        getAllMenuByCatId()
    }
      , [allCategory]);

      useEffect(() => {
        
    }
      , [location.state.cat_id]);
    
    const getAllMenuByCatId=async()=>{
        const resp=await getAllMenuById(location.state.cat_id)
        console.log("MENU RESP===>",resp.data.menues)
        setCategoryDetails(resp.data)
        setAllMenu(resp.data.menues)
    }

    return(
      <div>
     <Header/>

  <div className="global"> 
    
 
<div className="container container_min_height">
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading">{categoryDetails.name}</h3>
                {/* <button className="btn-default btn1">View All</button> */}
            </div>
            <div className="row d-flex align-items-center" style={{flexWrap:'wrap'}}>
                {allMenu.map((data, index) => (
                    <div className="col-lg-6 mb-5" key={index}>
                        <div className="row d-flex">
                            <div className="col-lg-5">
                                <figure style={{margin:'0 0 0 0'}}>
                                    <img src={config.FILE_URL+data.imageName} alt="" style={{width:'100%'}}/>
                                </figure>
                            </div>
                            <div className="col-lg-7">
                                    <p className="menuTitle mb-3">{data.name}</p>
                                    <p className="menuDesc">{data.description}</p>
                            </div>
                        </div>
                        
                        
                    </div>
                ))}
            </div>
        </div>
    </div>
    </div>

    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default MenuScreen;