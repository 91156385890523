import React, { useEffect, useState,} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { UserProfile } from "../Context/UserContext";
import slider1 from '../img/banner_1.jpg'
import config from "../Config/Config.json"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import noImg from '../img/no_img.png'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const Category=(props)=> {
  const navigate = useNavigate();
  const location = useLocation();
  const {allCategory}=UserProfile();
  const [imgLoading,setImgLoading]=useState(false)

  useEffect(() => {
         
    }
      , [imgLoading]);
  
  const gotoMenu=async(data)=>{
    navigate('/menu',{ state: { cat_id: data.id } })
}

const gotoCategory=async()=>{
    navigate('/categories')
}

  return (
    <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading">Latest Categories</h3>
                <button className="btn-default btn1" onClick={gotoCategory}>View All</button>
            </div>
            <div className="row d-flex align-items-center justify-content-center" style={{flexWrap:'wrap'}}>
            
            
                {allCategory.map((data, index) => (
                  <div 
                  className="col-lg-4 mb-5" 
                    key={index} 
                    style={{cursor:'pointer'}}
                    onClick={()=>gotoMenu(data)}
                    >
                        <div className="thumbnail">
                            <figure style={{margin:'0 0 0 0'}}>
                              
                                
                                <img 
                                src={config.FILE_URL+data.imageName+'?d='+new Date()} 
                                alt="" 
                                style={{width:'100%',height:'auto'}} 
                                
                                />
                             
                                
                                
                            </figure>
                            <div className="caption">
                                <p className="price">{data.name}</p>
                                
                            </div>
                        </div>
                    </div>
                ))}
            
        
        </div>
        </div>
  );
}

export default Category;
