import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"

import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime } from "../admin/Config/CommonFunction";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
//const CaptionedSlider = withCaption(AwesomeSlider);

const GalleryScreen = () => {

    const navigate = useNavigate();
    const {allGallery}=UserProfile();
    const [galleryModal,setGalleryModal]=useState(false)
    const [imagePath,setImagePath]=useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }
      , [allGallery]);
    
    const openModal=(path)=>{
        setImagePath(path)
        setGalleryModal(true)
    }
    const closeGalleryModal=()=>{
        setImagePath('')
        setGalleryModal(false)
    }
    return(
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Cafes In south kolkata | Gallery | The Lighter Side Cafe</title>
            <meta name="description" content="Discover the best cafes in South Kolkata at The Lighter Side Cafe. Explore our gallery and experience cozy vibes, delicious treats, and great coffee!" />
            <link rel="canonical" href={"https://thelighterside.in/"} ></link>
            <meta property="og:title" content="Cafes In south kolkata | Gallery | The Lighter Side Cafe" />
            <meta property="og:description" content="Discover the best cafes in South Kolkata at The Lighter Side Cafe. Explore our gallery and experience cozy vibes, delicious treats, and great coffee!" />
            <meta property="og:image" content="https://thelighterside.in/uploads/banner_images/ca896c1a-8fff-4a90-8160-f69c8eba1a47_BANNER.jpg" />
            <meta property="og:url" content="https://thelighterside.in/" />
            <meta property="og:type" content="website" />
      </Helmet>
     <Header/>

  <div className="global"> 
    
 
<div className="container container_min_height">
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading">Our Gallery</h3>
            </div>
            <div className="row d-flex align-items-center" style={{flexWrap:'wrap'}}>
                {allGallery.map((data, index) => (
                    <div className="col-lg-4 mb-5" 
                    key={index} 
                    style={{cursor:'pointer'}}
                    onClick={()=>openModal(config.FILE_URL+data.imageName)}
                    >
                        <div className="thumbnail">
                            <figure style={{margin:'0 0 0 0'}}>
                                <img src={config.FILE_URL+data.imageName} alt="" style={{width:'100%',height:'274px'}}/>
                            </figure>
                            
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <Modal
          show={galleryModal}
          onHide={closeGalleryModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                
                
                
              <img src={imagePath} alt="" style={{width:'100%'}}/>
                

              </div>
            </div>
          </Modal.Body>
          </Modal>
    </div>
    </div>

    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default GalleryScreen;