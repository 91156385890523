import React, { useEffect, useState,} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { UserProfile } from "../Context/UserContext";
import slider1 from '../img/banner_1.jpg'
import config from "../Config/Config.json"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const Testimonial=(props)=> {
  const navigate = useNavigate();
  const location = useLocation();
  const {allCategory}=UserProfile();
  
  

  return (
    <div className="forcombo py-2 px-3">
    <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h3 className="menu_heading_combo">Our Testimonials</h3>
                
            </div>
            <div>
           <Carousel
              responsive={responsive}
              autoPlay={true}
              //autoPlay={props.deviceType !== "mobile" ? true : false}
              swipeable={true}
              draggable={true}
              showDots={false}
              infinite={true}
              partialVisible={false}
              dotListClass="custom-dot-list-style"
              autoPlaySpeed={3000}
              itemClass="mz-3"
              slidesToSlide={1}
            >
                <div className="mb-5">
                    <div className="thumbnail">
                        
                        <div className="caption">
                            <div className="event_block testimonial_minHeight">
                                    <p className="title">Owner (Male)</p>
                                    <p className="description mb-2">
                                    “As the owner, my goal has always been to make wholesome food exciting. 
                                    The joy of offering fresh, flavourful dishes that align with a healthy lifestyle is what drives 
                                    me every day. So, at The Lighter Side Cafe, we’re not just serving meals; we’re serving a philosophy.
                                     Every dish is crafted with love and care, balancing taste and nutrition to perfection. 
                                     I love seeing our customers leave with a smile and a lighter step!”
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="thumbnail">
                        
                        <div className="caption">
                            <div className="event_block testimonial_minHeight">
                                    <p className="title">Owner (Female)</p>
                                    <p className="description mb-2">
                                    “The Lighter Side Cafe started as a simple idea: to bring a little more health and happiness 
                                    into people’s lives. Today, seeing our guests enjoy our menu and feel at home here reminds 
                                    me why I love what I do. When I designed this cafe, I wanted it to feel like a home away 
                                    from home. At this cafe, you’ll always find good food, good vibes, and a team that 
                                    genuinely cares about making your day a little brighter. 
                                    I’m grateful for every guest who walks through our doors and gives us the chance to 
                                    brighten their day with our food and hospitality.”
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="thumbnail">
                        
                        <div className="caption">
                            <div className="event_block testimonial_minHeight">
                                    <p className="title">Cook</p>
                                    <p className="description mb-2">
                                    “From our perfectly brewed coffee to our hearty breakfast options, 
                                    everything is made with care. It feels great to be a part of a team that values quality 
                                    and customer satisfaction. Every day, I step into the kitchen with a mission — to create 
                                    flavours that delight and dishes that inspire. At The Lighter Side, every recipe is a reflection of my love for cooking. 
                                    I believe food should be delicious and uplifting, just like the vibe here at The Lighter Side.
                                     Knowing people leave with full stomachs and happy hearts makes my day. 
                                     I love knowing that my work can brighten someone’s day, one plate at a time."
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="thumbnail">
                        
                        <div className="caption">
                            <div className="event_block testimonial_minHeight">
                                    <p className="title">Customer 1</p>
                                    <p className="description mb-2">
                                    “I’ve never tasted such creative combinations of flavours. 
                                    Their Butter Chicken Slider is a masterpiece and the desserts? 
                                    Guilt-free yet indulgent. The Lighter Side Cafe has redefined cafe dining for me. 
                                    Every visit is filled with good food, great coffee, and even better memories. 
                                    I especially love their seasonal menu — it’s always a fresh surprise! The atmosphere is charming & 
                                    I can’t imagine a better spot to meet friends or treat myself to some me-time. 
                                    Every time I step into The Lighter Side Cafe, I’m greeted with positivity and the aroma of 
                                    fresh coffee. Their menu is a perfect blend of comfort food and health-conscious options.”
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="thumbnail">
                        
                        <div className="caption">
                            <div className="event_block testimonial_minHeight">
                                    <p className="title">Customer 2</p>
                                    <p className="description mb-2">
                                        “Whether I need a quick breakfast or a leisurely lunch, 
                                        The Lighter Side Cafe has me covered. Their Mexican Burger is the best 
                                        I’ve had in town, and the fresh Blue Heaven Mojito is a game-changer. 
                                        You can taste the freshness and quality of every dish. 
                                        The hummus platter is my personal favourite — it’s a must-try for anyone visiting for 
                                        the first time. I’ve explored countless cafes, but this one takes the crown. 
                                        The calm atmosphere and wholesome dishes make it my go-to spot to recharge – Highly 
                                        Recommended!”
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="thumbnail">
                        
                        <div className="caption">
                            <div className="event_block testimonial_minHeight">
                                    <p className="title">Customer 3</p>
                                    <p className="description mb-2">
                                    “The Lighter Side Cafe is a little corner of bliss in the city. 
                                    The ambience is calming, the staff is friendly, and the food always hits the spot. 
                                    Every dish I’ve tried here is packed with freshness and flavour. 
                                    It’s rare to find a place where everything feels perfect—the food, the service, and the vibe. 
                                    The Lighter Side Cafe is that rare gem I keep coming back to. The team here always goes above and beyond to 
                                    make you feel welcome. It’s not just about the food (which is amazing); 
                                    it’s the people that make this place truly special. Every time I’m here, 
                                    I feel like the menu was made just for me. It’s always exactly what I’m craving, whether it’s a 
                                    hearty meal or a light snack. Thank You The Lighter Side!” 
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
                </Carousel>
            </div>
    </div>
</div>
  );
}

export default Testimonial;
