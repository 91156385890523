import React, { useEffect, useState,} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { UserProfile } from "../Context/UserContext";
import slider1 from '../img/banner_1.jpg'
import config from "../Config/Config.json"
const Banner=(props)=> {
  const navigate = useNavigate();
  const location = useLocation();
  const {allBanner}=UserProfile();
  

  return (
    <div className="slider">
        

    <Carousel
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
    >
        {allBanner.map((data, index) => (
        <div key={index}>
            <img src={allBanner.length>0?config.FILE_URL+data.imageName+'?d='+new Date():slider1} />
            {/* <p className="legend">Legend 1</p> */}
        </div>
        ))}
        {/* <div>
            <img src={slider2} />
            <p className="legend">Legend 2</p> 
        </div> */}
        
    </Carousel>

    


</div>
  );
}

export default Banner;
