import React, { useEffect, useState, } from "react";
import companyLogo from '../assets/images/logo-3-bg.png';
import Header from "../common/Header.js";
import SideMenu from "../common/SideMenu.js";
import Footer from "../common/Footer.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext.js";
import { AdminProfile } from "../Context/AdminContext.js";
import { Modal } from "react-bootstrap";
import edit from "../assets/images/edit.png";
import trash from "../assets/images/trash.png"
import {convertDateTime,convertTime} from "../Config/CommonFunction.js"
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const BlogPage = () => {

  const navigate = useNavigate();
  const { authData} = useAuth();
  const {blogList,addBlog,updateEvent,activeInactiveEvent}=AdminProfile();
  const [categoriesModal, setcategoriesModal] = useState(false);
  const [blogTitle, setBlogTitle] = useState('');
  const [blogDesc, setBlogDesc] = useState('');
  const [editedBlogId, setEditedBlogId] = useState(0);
  const [imageHandler, setImageHandler] = useState(null)

  useEffect(() => {
    console.log("bloglist===>",blogList)
  }
    , [authData]);

    useEffect(() => {
    }
      , [blogList]);

    const openCategoeyModal=()=>{
      setEditedBlogId(0)
      setBlogTitle('')
      setBlogDesc('')
      setcategoriesModal(true)
    }

    const closecategoriesModal=()=>{
      setcategoriesModal(false)
    }

    const uploadImage=(e)=>{
      // let arr=[]
      // for(let i=0;i<e.target.files.length;i++){
      //   arr.push(e.target.files[i])
      // }
      setImageHandler(e.target.files)
    }

    const saveCategory=async()=>{
      console.log("IMG FILES===>",imageHandler)
      if(blogTitle==='' || blogDesc==='')
      {
        alert('All * marks fields are mandatory')
      }
      else{
        let resp=''
        const formData = new FormData();
        formData.append('id', editedBlogId)
        formData.append('Title', blogTitle)
        formData.append('Author', 'Admin')
        formData.append('Description', blogDesc)
        formData.append('imageFile', imageHandler)
        
        if(editedBlogId===0){
          resp=await addBlog(formData)
        }
        else{
          resp=await updateEvent(formData)
        }
        
        setcategoriesModal(false)
        if(resp.data.status==="success"){
          alert(resp.data.message)
        }
        else{
          alert("Failed to save")
        }
      }
    }

    const openEditSection=async(data)=>{
      setEditedBlogId(data.id)
      setBlogTitle(data.name)
      setBlogDesc(data.description)
      setcategoriesModal(true)
      
    }

    const changeStatus=async(id)=>{
      const resp=await activeInactiveEvent(id)
    }

    
    return(
      <div className="container-scroller">
      
      
      <Header/>
      
      <div className="container-fluid page-body-wrapper">
        
        <SideMenu/>
        
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header d-flex justify-conetent-between">
              <h3 className="page-title">
                 Manage Blog
              </h3>

              <button className="btn btn-dark btn-fw" onClick={openCategoeyModal}>
                  Add
              </button>
              
            </div>
            
            
            <div className="row">
            
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th> Title </th>
                            <th> Description </th>
                            <th> Date </th>
                            
                            <th> Status </th>
                            <th> </th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                        {blogList.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {data.title}
                            </td>
                            <td > 
                              <div dangerouslySetInnerHTML={{__html: data.description.substring(0, 150)+"..."}}/>
                            </td>
                            <td >
                              {convertDateTime(data.creationDate)}
                            </td>
                            
                            <td>
                              {data.isActive===true?(
                                <label className="badge badge-gradient-success">Active</label>
                              ):(
                                <label className="badge badge-gradient-danger">Inactive</label>
                              )}
                            </td>
                            <td> 
                            <button 
                            className="btn btn-inverse-primary btn-icon"
                            onClick={()=>openEditSection(data)}
                            >
                                  <img 
                                    src={edit}
                                    style={{
                                      height:'24px',
                                      width:'24px'
                                    }}
                                  />
                              </button>
                            </td>
                            <td>
                            <BootstrapSwitchButton 
                              checked={data.isActive} 
                              onstyle="success" 
                              offstyle="secondary" 
                              size="lg"
                              onChange={() => changeStatus(data.id)}
                              />
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                
              </div>
            
            </div>
            
          </div>
          <Footer/>
          
          
        </div>
        
      </div>

      {/* ------------Add Edit Category Modal------------ */}

      <Modal
          show={categoriesModal}
          onHide={closecategoriesModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <label className="form-label">Title <span style={{color:'red'}}>*</span></label>
                <input 
                    type="text" 
                    className="form-control mb-2" 
                    placeholder="Type Blog Title"
                    value={blogTitle} 
                    onChange={(e) => setBlogTitle(e.target.value)} 
                />
                
                
                
                <label className="form-label">Description <span style={{color:'red'}}>*</span></label>
                {/* <textarea
                  className="form-control mb-2" 
                  value={eventDesc} 
                  onChange={(e) => setEventDesc(e.target.value)} 
                /> */}
                <div className="form-control mb-2" style={{height:'200px'}}>
                  <ReactQuill 
                    theme="snow" 
                    value={blogDesc} 
                    onChange={setBlogDesc}
                    style={{height:'120px'}}
                  />
                </div>
                
                <label className="form-label">Upload Image <span style={{color:'red'}}>*</span></label>
                <input 
                  type="file" 
                  className="form-control mb-2" 
                  onChange={uploadImage}
                  multiple={true}
                  />

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            
            <button className="btn btn-outline-dark btn-fw" onClick={saveCategory} style={{ width: '20%' }}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      
    </div>
    )
}

export default BlogPage;