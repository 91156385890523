import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"
import event1 from '../img/2150951243.jpg'
import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime, convertTime } from "../admin/Config/CommonFunction";
import { Modal } from "react-bootstrap";


//const CaptionedSlider = withCaption(AwesomeSlider);

const BlogDetailsScreen = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {allEvent,getBlogDetailsById,allGallery}=UserProfile();
    const [blogDetails,setBlogDetails]=useState('')
    const [blogDate,setBlogDate]=useState('')
    const [eventTime,setEventTime]=useState('')
    const [galleryModal,setGalleryModal]=useState(false)
    const [imagePath,setImagePath]=useState('')


    useEffect(() => {
        window.scrollTo(0, 0)
        getEventdetails();
    }
      , [location.state.blog_id]);


    const getEventdetails=async()=>{
        const resp=await getBlogDetailsById(location.state.blog_id)
        console.log("RESP ARRAY===>",resp)
        setBlogDetails(resp.data)
        setBlogDate(convertDateTime(resp.data.creationDate))
       
       
        
    }
    const openModal=(path)=>{
        setImagePath(path)
        setGalleryModal(true)
    }
    const closeGalleryModal=()=>{
        setImagePath('')
        setGalleryModal(false)
    }
    return(
      <div>
     <Header/>

  <div className="global" style={{backgroundColor:'#f6e4c2'}}> 
    
 
  <div className="container container_min_height">
        
        <div className="col-lg-12 events mb-5">
            
            <div className="row d-flex pt-3" style={{flexWrap:'wrap'}}>
                <div className="col-lg-6">
                    <img src={blogDetails.imageName===null?event1:config.FILE_URL+blogDetails.imageName} alt="" style={{width:'100%'}}/>
                </div>
                <div className="col-lg-6" style={{marginTop:'10%'}}>
                    <p className="menuTitle mb-3" >{blogDetails.title}</p>
                    <p className="menuTitle mb-3">{blogDate}</p>
                    <p className="menuDesc" dangerouslySetInnerHTML={{__html: blogDetails.description}}/>
                </div>
            </div>
        </div>
    </div>

    

<Modal
          show={galleryModal}
          onHide={closeGalleryModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                
                
                
              <img src={imagePath} alt="" style={{width:'100%'}}/>
                

              </div>
            </div>
          </Modal.Body>
          </Modal>
    
    
    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default BlogDetailsScreen;